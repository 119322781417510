import { useClientEnvContext } from '~/providers';

type LineHook = {
  lineTokeUrl: string;
};

export const useLine = (): LineHook => {
  const { lineAccountId } = useClientEnvContext();
  const lineTokeUrl = `https://line.me/R/oaMessage/%40${lineAccountId}`;
  return { lineTokeUrl };
};
